import React from 'react'
import PrivatePrimaryCoursesIMG from './PrivatePrimaryCourses.jpeg'
import five from './PrimarySchoolPhotos/five.jpeg'
import one from './PrimarySchoolPhotos/one.jpeg'
import three from './PrimarySchoolPhotos/three.jpeg'

import timetable from './PrimarySchoolPhotos/timetable.jpg'


const PrivatePrimaryCourses = () => {
  const tableStyle = {
    border: '1px solid black', // Set the border style here
    borderCollapse: 'collapse', // Optional: To collapse borders
    minWidth: '1000px', // Optional: Set the table width
    // overflowX: 'scroll'
    width: '100%'
};

const cellStyle = {
    border: '1px solid black', // Set the border style for cells
    padding: '8px', // Optional: Add padding to cells
};

  return (
    <div style={{minHeight: '100vh'}}>
        <div className="d-lg-flex container boarder align-items-center" style={{minHeight: '500px'}}>
          <div className='col-lg-6'>
            <h2 className='mb-3'>Private Primary Courses</h2>
            <h5>Private Primary Courses (Ages 5 to 9)</h5>
            <p style={{textAlign: 'justify'}}>These are the courses recognized by Ministry of Education. We have courses
            from KG to Grade (5). The course structure is framed by combining two
            curricula; Myanmar Curriculum and International Curriculum. For Myanmar
            Curriculum, we teach them Myanmar, English, Maths, General Science, Geography and
            History as it is mentioned in Myanmar Official Text books. For international
            Curriculum, we encourage the students to use English in classroom and encourage
            them to study English, Maths and General Knowledge for certain extent. Our main
            purpose here is to teach the primary students Myanmar Curriculum thoroughly and
            International course in limited ways in order to enhance them to be well-trained
            students.</p>
            {/* <h5>Kindergarten Ages (4 to 5)</h5>

            <p style={{textAlign: 'justify'}}>This is specially aimed for kids who are about to attend primary courses in near
            future. We give them a very high exposure of learning Myanmar, English, Maths and
            General Knowledge in order to have the knowledge of Myanmar Government
            curriculum and International curriculum. For the purpose of giving them “fun and
            learn” attitude, we have special extra curriculum activities. We aim our students to list
            in both locally and internationally.</p> */}
            </div>
          <div className='col-lg-6 d-flex justify-content-center '>
            <img className='ms-lg-auto' src={PrivatePrimaryCoursesIMG} style={{width: '95%', objectFit: 'contain', borderRadius: '5px'}} alt="" />
          </div>
        </div>
        {/* <div className='container' style={{overflow: 'auto'}}>
          <h2 className='mb-4 mt-3 mt-lg-0'>Our Courses</h2>
        <table style={tableStyle}>
            <thead>
                <tr>
                    <th style={cellStyle} className='col-1'>စဉ်</th>
                    <th style={cellStyle} className='col-2'>ကျောင်း</th>
                    <th style={cellStyle} className='col-3'>အတန်း</th>
                    <th style={cellStyle} className='col-3'>သင်ရိုး</th>
                    <th style={cellStyle} className='col-2'>ဖြည့်စွက်သင်ရိုး</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={cellStyle} rowSpan="6">1</td>
                    <td style={cellStyle} rowSpan="6">မူလတန်း</td>
                    <td style={cellStyle}>သူငယ်တန်း</td>
                    <td style={cellStyle} rowSpan="5">
                    အခြေခံပညာသင်ရိုး  <br />
                    Cambridge Curriculum <br />
                    English <br />
                    Maths <br />
                    Science
                    </td>
                    <td style={cellStyle} rowSpan="6">
                    Art <br />
                    Music <br />
                    (Ukulele/Recorder) <br />
                    Dance <br />
                    Computer <br />
                    Sports / Yoga
                    </td>
                </tr>
                <tr>
                    <td style={cellStyle}>ပထမတန်း</td>
                    
                </tr>
                <tr>
                    <td style={cellStyle}>ဒုတိယတန်း</td>
                </tr>
                <tr>
                    <td style={cellStyle}>တတိယတန်း</td>
                </tr>
                <tr>
                    <td style={cellStyle}>စတုတ္ထတန်း</td>
                </tr>
                <tr>
                    <td style={cellStyle}>ပဉ္စမတန်း</td>
                    <td style={cellStyle}>
                    အခြေခံပညာသင်ရိုး  <br />
                    KET(Cambridge Exam Preparation) <br />
                    Maths,Science
                    </td>
                </tr>
            </tbody>
        </table>
        </div> */}
        <div className='container mt-4 mt-lg-0 col-12 mt-5' style={{overflow: 'auto'}}>
        <img className='timetableone' style={{objectFit: 'contain', overflow: 'hidden'}} src={timetable} alt="" />
        </div>
        <div className="container py-lg-5 pt-5 px-0">
          <h2 className='mb-4 ps-3'>Our Class Photo</h2>
          <div className="d-lg-flex">
            <div className='col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={five} alt="" />
            </div>
            <div className='col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={one} alt="" />
            </div>
            <div className='col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={three} alt="" />
            </div>
            {/* <div className='col-3 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={PrivatePrimaryCoursesIMG} alt="" />
            </div> */}

            
          </div>
        </div>
    </div>
  )
}

export default PrivatePrimaryCourses