
  
  export const sliderOne = [
    "./ShinSawPuActivities/1.jpeg",
    "./ShinSawPuActivities/2.jpeg",
    "./ShinSawPuActivities/3.jpeg",
    "./ShinSawPuActivities/4.jpeg",
    "./ShinSawPuActivities/5.jpeg"];

    export const sliderTwo = [
      "./ParamiActivities/1.jpeg",
      "./ParamiActivities/2.jpeg",
      "./ParamiActivities/3.jpeg",
      "./ParamiActivities/4.jpeg",
      "./ParamiActivities/5.jpeg",
      "./ParamiActivities/6.jpeg",
      "./ParamiActivities/7.jpeg"];

      export const sliderThree = [
        "./YadanarActivities/1.jpg",
        "./YadanarActivities/2.jpg",
        "./YadanarActivities/3.jpg",
        "./YadanarActivities/4.jpg",
        "./YadanarActivities/5.jpg"];