import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MainNav from './Navbar';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Home from './Home';
import Footer from './Footer';
import Contact from './Contact';
import About from './About';
import PrivatePrimaryCourses from './Classes/PrivatePrimaryCourses';
import WeekendClasses from './Classes/WeekendClasses';
import NurseryPreKindergartenClasses from './Classes/NurseryPreKindergartenClasses';
import PrivateHighSchoolClasses from './Classes/PrivateHighSchoolClasses';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
      <MainNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/classes/NurseryPreKindergartenClasses" element={<NurseryPreKindergartenClasses />} />
          <Route path="/classes/PrivatePrimaryCourses" element={<PrivatePrimaryCourses />} />
          <Route path="/classes/PrivateHighSchoolClasses" element={<PrivateHighSchoolClasses />} />
          <Route path="/classes/WeekendClasses" element={<WeekendClasses />} />
        </Routes>
      <Footer />
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
