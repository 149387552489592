
import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import $ from 'jquery';
import logo from './logo3.png';

const MainNav = () => {

  useEffect(() => {
    $('.menu-toggle').click(function(){
      $('.site-nav').toggleClass('site-nav--open', 500);
      $(this).toggleClass('open');
    });
  }, []);

  console.log(window.location.href.split("/")[3])

  return (
  //   <nav>
  //   <div className="container">
  //     <h1 className="logo"><img style={{width: '50px'}} src={logo} alt="" /></h1>
  //     <ul className="site-nav">
  //       <li><a href="#"><i className="fa fa-home site-nav--icon"></i>Home</a></li>
  //       <li><a href="#"><i className="fa fa-info site-nav--icon"></i>About</a></li>
  //       <li><a href="#"><i className="fa fa-pencil site-nav--icon"></i>Classes</a></li>
  //       {/* <li><a href="#"><i className="fa fa-usd site-nav--icon"></i>Pricing</a></li> */}
  //       <li><a href="#"><i className="fa fa-envelope site-nav--icon"></i>Contact</a></li>
  //     </ul>
    
  //     <div className="menu-toggle">
  //       <div className="hamburger"></div>
  //     </div>
  //   </div>
  // </nav>
  <nav style={{position: 'sticky',background: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', color: 'white !important',zIndex: '1000000', top: '0' ,boxShadow: '0px 0px 22px 0px rgba(54.093546195652166, 89.25553904773156, 251.34374999999997, 0.1)'}} class="bounce-in-top navbar navbar-expand-lg py-3">
  <div class="container-fluid container">
   
    <a class="navbar-brand" href="/"><img style={{width: '60px', background: 'white', borderRadius: '6px', paddingLeft: '.19rem'}} src={logo} alt="" /><span className='ms-3 mb-0 streamlineNavText' style={{fontWeight: 'bold',color: 'white', fontSize: '1.8rem'}}>Streamline Training-Learning Centre</span></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul style={{fontSize: '1rem'}} class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item pb-2 pb-lg-0 px-3">
          <a style={{fontWeight: '700', color: 'white'}} class={`nav-link ${window.location.href.split("/")[3] ? "" : 'active'}`} aria-current="page" href="/">HOME</a>
        </li>
        <li class="nav-item pb-2 pb-lg-0 px-3">
          <a style={{fontWeight: '700', color: 'white'}} class={`nav-link ${window.location.href.split("/")[3] == 'about' ? "active" : ''}`} aria-current="page" href="/about">ABOUT</a>
        </li>
        {/* <li class="nav-item pb-2 pb-lg-0 dropdown px-3">
          <a style={{fontWeight: '700'}} class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            ABOUT
          </a>
          <ul style={{zIndex: '1000 !important'}} class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider" /></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li> */}
        <li class="nav-item pb-2 pb-lg-0 dropdown px-3">
          <a style={{fontWeight: '700', color: 'white'}} class={`nav-link dropdown-toggle ${window.location.href.split("/")[3] == 'classes' ? "active" : ''}`} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            CLASSES
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class={`dropdown-item ${window.location.href.split("/")[4] == 'NurseryPreKindergartenClasses' ? "activedropdown" : ''}`} href="/classes/NurseryPreKindergartenClasses">Nursery & Pre-Kindergarten Classes</a></li>
            <li><a class={`dropdown-item ${window.location.href.split("/")[4] == 'PrivatePrimaryCourses' ? "activedropdown" : ''}`} href="/classes/PrivatePrimaryCourses">Private Primary Classes <span className='dnonenavtext'>( KG to GRADE - 4 )</span></a></li>
            <li><a class={`dropdown-item ${window.location.href.split("/")[4] == 'PrivateHighSchoolClasses' ? "activedropdown" : ''}`} href="/classes/PrivateHighSchoolClasses">Private High School Classes <span className='dnonenavtext'>( GRADE- 6 to GRADE-12 )</span></a></li>
            <li><a class={`dropdown-item ${window.location.href.split("/")[4] == 'WeekendClasses' ? "activedropdown" : ''}`} href="/classes/WeekendClasses">Weekend Classes</a></li>
            {/* <li><a class={`dropdown-item ${window.location.href.split("/")[4] == 'WeekendClasses' ? "activedropdown" : ''}`} href="/classes/WeekendClasses">Summar Classes</a></li> */}


            
            {/* <li><hr class="dropdown-divider" /></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li> */}
          </ul>
        </li>
        <li class="nav-item pb-2 pb-lg-0 px-3">
          <a style={{fontWeight: '700', color: 'white'}} class={`nav-link ${window.location.href.split("/")[3] == 'contact' && 'active'}`} href="/contact">CONTACT</a>
        </li>
        {/* <li class="nav-item pb-2 pb-lg-0">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li> */}
      </ul>
      <form class="d-lg-flex d-none">
        
        {/* <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
        <button class="btn btn-outline-success" type="submit">Search</button> */}
         <a href='https://www.facebook.com/profile.php?id=100070428040332&mibextid=LQQJ4d' class="border-0 me-2 d-flex justify-content-center align-items-center text-decoration-none" style={{background: '#7F367B', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer'}}>
         <i class="fa-brands text-white fa-facebook-f border-0"></i>
        </a>
        {/* <a class="border-0 me-2 d-flex justify-content-center align-items-center text-decoration-none" style={{background: '#7F367B', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer'}}>
        <i class="fa-brands text-white fa-linkedin-in"></i>
        </a>
        <a class="border-0 me-2 d-flex justify-content-center align-items-center text-decoration-none" style={{background: '#7F367B', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer'}}>
        <i class="fa-brands text-white fa-instagram"></i>
        </a> */}
      </form>
      
    </div>
  
  </div>
</nav>
  )
}

export default MainNav