import React, { useState } from 'react'
import about from './photos/about.jpg'
import mission from './photos/mission.jpg'
import vision from './photos/vision.jpg'
import './App.css'

import class1 from './Classes/ShinSawPuPhotos/cover.jpeg'
import class2 from './Classes/PrimarySchoolPhotos/cover.jpeg'
import class3 from './Classes/PrivateHighSchoolPhotos/cover.jpg'
import class4 from './Classes/weekendPhotos/cover.jpg'

const About = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    console.log(name, email, phone, message)

    const apiUrl = 'https://emailapi.ethical-digit.com/api/send-email';

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ name, message, email, phone, subject: 'Innovative Learning Insights: Streamline School Edition', company_email: 'infostreamlinetlc@gmail.com', company_name: 'Streamline Training-Learning Centre', company_address: '', company_logo: 'https://streamline.ethical-digit.com/emailLogo.png', company_phone: '01-657694, 01-662304' }),
            body: JSON.stringify({ name, message, email, phone, subject: 'Innovative Learning Insights: Streamline School Edition', company_email: 'infostreamlinetlc@gmail.com', company_name: 'Streamline Training-Learning Centre', company_address: '', company_logo: 'https://streamline.ethical-digit.com/emailLogo.png', company_phone: '01-657694, 01-662304', domain: 'streamline.ethical-digit.com' }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setEmail('')
                setMessage('')
                setPhone('')
                setName('')
                // Handle the response data as needed
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle errors
            });
    }

    return (
        <div>
            <div className='d-flex flex-wrap justify-content-center align-items-center container mb-3'>
                <div className='fade-in-fwd col-lg-6 col-12 text-start d-flex flex-column justify-content-center align-items-center py-4 py-lg-0 mb-3 mb-lg-0' style={{ minHeight: 'calc(90vh - 20px)' }}>
                    <h2 className='text-start w-100' style={{ fontSize: "48px", color: '#7F367B', fontWeight: '600', lineHeight: '58px', letterSpacing: '.3rem' }}>Welcome to Streamline Training-Learning Centre<br />
                        {/* <span style={{color: '#7F367B', borderBottom: '5px solid #7F367B', cursor: 'pointer'}}>Anything</span> */}
                        {/* <div class="containers">
                <svg width="300" height="100" viewBox="0 0 960 300">
                    <symbol id="s-text">
                    <text text-anchor="start" x="0%" y="70%">Anything</text>
                    </symbol>

                    <g className="g-ants">
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    </g>
                </svg>
                </div> */}
                    </h2>
                    <p className='pt-4' style={{ textAlign: 'justify' }}>
                    STLC was founded in early 2006 and started its very first Summer Camp on 12th March 2007 with 7 students. Followed by the first academic study year in June 2006 and have seen it growing rapidly with over 200 students. Our summer classes include programs for
                        children up to 14 years of age. The subjects taught are varied according to the
                        different courses we offered at STLC. In general, all courses are taught in such a way
                        that the four language skills listening, speaking, reading and writing as well as Basic
                        English Grammar, Vocabulary, Development and International English Pronunciation,
                        General Knowledge, Art and Craft, Physical Education, Basic Computer, Sports, Music
                        and Dance are acquired.</p>
                    <div className='w-100 d-flex'>
                        {/* <button  className="button-89 me-4" role="button">
                    Learn More
                    </button>  */}
                        <a href="/#ourclasses" className='text-decoration-none'><button className="button-87" role="button">
                            Classes <span className='ms-2'><i class="fa-solid fa-arrow-right"></i></span>
                        </button></a>

                        {/* <button  className="button-87" role="button">
                    Contact
                    </button>  */}
                    </div>

                </div>
                <div style={{ zIndex: '1', backgroundImage: "url('https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/elements.png.webp')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className='flip-in-hor-top col-lg-6 col-12 d-flex justify-content-center'>
                    <img class=" w-lg-90 bg-light p-3 ms-lg-auto" style={{ border: '2px solid #7F367B', borderRadius: '3px', width: '95%' }} src={about} alt="" />

                </div>
                {/* <div style={{zIndex: '1' ,backgroundImage: "url('https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/elements.png.webp')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} className='flip-in-hor-top d-flex col-lg-6 col-12 text-center m-auto justify-content-center relative'>
                <img class="welcomeImageHover img-fluid w-75 rounded-circle bg-light p-3" style={{border: '2px solid #7F367B'}} src="https://themewagon.github.io/kider/img/about-1.jpg" alt="" />
                <img class="welcomeImageHover img-fluid rounded-circle bg-light p-3 absolute" style={{border: '2px solid #7F367B', position: 'absolute',bottom: '0',left: '0', width: '30%'}} src="https://themewagon.github.io/kider/img/about-2.jpg" alt="" />
                <img class="welcomeImageHover img-fluid rounded-circle bg-light p-3 absolute" style={{border: '2px solid #7F367B', position: 'absolute',bottom: '0',right: '0', width: '30%'}} src="https://themewagon.github.io/kider/img/about-3.jpg" alt="" />
            
            </div> */}

            </div>
            <div className='d-flex flex-wrap justify-content-center align-items-center p-0 container mt-5 col-11' style={{ border: '2px solid #7F367B', borderRadius: '13px', overflow: 'hidden' }}>
                <div className='flip-in-hor-top d-lg-flex col-lg-6 col-12 text-center'>
                    <img style={{ width: '100%', borderRadius: '13px' }} src={mission} alt="" />
                </div>
                <div className='fade-in-fwd col-lg-6 col-12 text-start d-flex flex-column justify-content-start align-items-center p-4'>
                    <p className='mb-0 text-start me-auto' style={{ fontSize: '.9rem', color: '#E9911F' }}>ABOUT US</p>
                    <h2 className='text-start w-100 title1' style={{ letterSpacing: '.3rem', color: 'black' }}>Our Mission<br />
                    </h2>
                    {/* <p className='pt-0'>	Mauris malesuada enim eget blandit gravida. Duis hendrerit cursus turpis, id mollis est rutrum nec. Sed interdum nisi id libero tincidunt, sit amet vestibulum tortor porttitor. Cras ligula lacus, ullamcorper se</p> */}
                    <ul className='p-0 m-0 me-auto'>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To provide the best learning   environment for the students</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To give a holistic education</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To nurture the global leaders</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To participate in both local and international students competitions</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To initiate student camps and international exchange programs</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To expand more creativity & art classes to support students’ soft skills </span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To emphasize music & sports more professionally </span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To upgrade STLC into internationally recognized private school of Myanmar </span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To become the Singapore Accredited Myanmar Private School </span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To link with famous international universities across the world  </span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> To set up STLC branches across Myanmar </span></li>


                    </ul>

                </div>


            </div>
            <div className='d-flex flex-wrap justify-content-center align-items-center p-0 container mt-5 pt-lg-2' style={{ borderRadius: '13px', overflow: 'hidden' }}>

                <div className='fade-in-fwd col-12 col-lg-6 text-start d-flex flex-column justify-content-start align-items-center p-4'>
                    <p className='mb-0 text-start me-auto' style={{ fontSize: '.9rem', color: '#E9911F' }}>ABOUT US</p>
                    <h2 className='text-start w-100 title1' style={{ letterSpacing: '.3rem', color: 'black' }}>Our Vision<br />
                    </h2>
                    {/* <p className='pt-0'>	Mauris malesuada enim eget blandit gravida. Duis hendrerit cursus turpis, id mollis est rutrum nec. Sed interdum nisi id libero tincidunt, sit amet vestibulum tortor porttitor. Cras ligula lacus, ullamcorper se</p> */}
                    <ul className='p-0 m-0 me-auto'>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> Our aim is to provide the best learning environment for the students instilling
                            the skills through listening, speaking, reading and writing in English.</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> We endeavor
                            constantly and consistently to teach them the positive social skills needed for building
                            our future society.</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> Creativity and originality as well as critical and reflective thought is
                            nurtured.</span></li>
                        <li style={{ listStyleType: 'none' }} className='d-flex justify-content-start align-items-top'><i class="fa-solid fa-pencil pe-2"></i><span> Thus the children learn to use English in all aspects of life and will hopefully
                            go on to have the highest aspirations in their educational future.</span></li>
                    </ul>

                </div>
                <div className='flip-in-hor-top d-flex col-12 col-lg-6 text-center justify-content-center'>
                    {/* <img style={{width: '100%', borderRadius: '50%', border: '2px solid #7F367B'}} src={vision} alt="" /> */}
                    <img class=" w-90 bg-light p-3 ms-lg-auto" style={{ border: '2px solid #7F367B', borderRadius: '3px', width: '95%' }} src={vision} alt="" />

                </div>

            </div>
            <div className='mx-lg-5 mx-2 px-2 py-lg-3 mt-5 mb-3 mb-lg-0 mt-lg-4 mb-lg-5 py-2 text-center' style={{ background: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', borderRadius: '10px', color: 'white !important' }}>
                <h2 className='title1'>Our Classes</h2>
                <div className='d-lg-flex justify-content-center align-items-center'>
                    {/* <p style={{color: 'white'}} className='col-lg-8 text-center'>Nam mattis felis id sodales rutrum. Nulla ornare tristique mauris, a laoreet erat ornare sit amet. Nulla sagittis faucibus lacus</p> */}

                    {/* <button  className="button-87" role="button">
                   <span className='pe-2'>All Classes</span>  <i class="fa-solid fa-arrow-right"></i>
                </button> */}
                </div>
                <div className="d-flex flex-wrap justify-content-around text-start align-items-top pt-lg-3 pt-4">
                    <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/NurseryPreKindergartenClasses">
                        <img style={{ width: '100%', height: '250px' }} src={class1} alt="" />
                        <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                            {/* <a className='text-decoration-none' href="/classes/NurseryClass"> */}
                            <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Nursery & Pre-Kindergarten Classes</h4>
                            {/* </a> */}

                        </div></a>
                    </div>
                    <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/PrivatePrimaryCourses">
                        <img style={{ width: '100%', height: '250px' }} src={class2} alt="" />
                        <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                            {/* <a className='text-decoration-none' href="/classes/KindergartenClass"> */}
                            <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Private Primary Classes (KG to GRADE - 4)</h4>
                            {/* </a> */}

                        </div></a>
                    </div>
                    <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/PrivateHighSchoolClasses">
                        <img style={{ width: '100%', height: '250px' }} src={class3} alt="" />
                        <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                            {/* <a className='text-decoration-none' href="/classes/PrivatePrimaryCourses"> */}
                            <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Private High School Classes (GRADE - 6 to GRADE - 12)</h4>
                            {/* </a> */}

                        </div></a>
                    </div>
                    <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/WeekendClasses">
                        <img style={{ width: '100%', height: '250px' }} src={class4} alt="" />
                        <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                            {/* <a className='text-decoration-none' href="/classes/PrivatePrimaryCourses"> */}
                            <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Weekend Classes</h4>
                            {/* </a> */}

                        </div></a>
                    </div>
                    {/* <div className='secondHomeHover gridtwo me-4 mb-5' style={{boxShadow: "0px 0px 10px 0px rgba(21, 87, 153, 0.1)", borderTopLeftRadius: '10px', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px'}}>
                    <img style={{width: '100%', }} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/school-counselor-and-pupil-768x512.jpg.webp" alt="" />
                    <div className='p-3' style={{borderBottom: '2px solid #575757', borderTop: '2px solid #575757'}}>
                        <p><span className='pe-2' style={{fontStyle: 'italic'}}>Mon-Fri</span> <span style={{fontStyle: 'italic'}}>10 AM - 12 AM</span></p>
                        <a className='text-decoration-none' href="EnvironmentalandStudiesEarthSciences"><h4 style={{fontSize: '20px', fontWeight: '500', lineHeight: '28px'}}>Environmental Studies & Earth Sciences</h4></a>
                        <div className='d-flex align-items-center pt-1'>
                            <img style={{width: '35px', borderRadius: '50%'}} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/elementor/thumbs/contemporary-businessman-pfuoi73guodv2uabxu7r47ifq5vfufxpjginqof5fe.jpg.webp" alt="" />
                           
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
            <div style={{ backgroundImage: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', borderRadius: '25px' }} className='container border d-flex flex-wrap justify-content-center align-items-center p-lg-5 p-4'>
                <div className='col-lg-6 col-12 text-white'>
                    <h3 style={{ fontSize: '36px', fontWeight: '700', lineHeight: '1' }}>Join us <br />and stay tuned!</h3>
                    <p>For more and detail information, you are mostly welcome to visit our school in
                        person. </p>
                </div>
                <div className='col-lg-6 col-12'>
                    <form onSubmit={handleSubmit}>
                        <div className='d-flex justify-content-between align-items-top'>
                            <div className='col-6 d-flex flex-column'>
                                <input onChange={(e) => setName(e.target.value)} value={name} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Name' type="text" name='Name' />
                                <input onChange={(e) => setEmail(e.target.value)} value={email} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Email' type="email" name='Email' />
                                <input onChange={(e) => setPhone(e.target.value)} value={phone} className='px-3 py-2' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Phone' name='Phone' />
                            </div>
                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} className='col-6 p-3' style={{ border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Message' name="Message" id="" cols="30" rows="3"></textarea>

                        </div>
                        <button type='submit' style={{ border: '2px solid #7F367B' }} className="button-87 ms-auto" role="button">
                            Send  <span className='ms-2'><i class="fa-solid fa-arrow-right"></i> </span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default About