import React from 'react'
import PrivatePrimaryCoursesIMG from './PrivatePrimaryCourses.jpeg'
import cover from './PrivateHighSchoolPhotos/cover.jpg'
import eng from './PrivateHighSchoolPhotos/Eng.jpg'
import math from './PrivateHighSchoolPhotos/STLC Maths-2.jpg'
import science from './PrivateHighSchoolPhotos/STLC Science.jpg'

import timetable1 from './PrivateHighSchoolPhotos/3.jpg'
import timetable2 from './PrivateHighSchoolPhotos/4.jpg'


const PrivateHighSchoolClasses = () => {
  const tableStyle = {
    border: '1px solid black', // Set the border style here
    borderCollapse: 'collapse', // Optional: To collapse borders
    minWidth: '1000px', // Optional: Set the table width
    width: '100%'
};

const cellStyle = {
    border: '1px solid black', // Set the border style for cells
    padding: '8px', // Optional: Add padding to cells
};

  return (
    <div style={{minHeight: '100vh'}}>
        <div className="d-lg-flex container boarder align-items-center" style={{minHeight: '500px'}}>
          <div className='col-lg-6'>
            <h2 className='mb-3'>Private High School Classes</h2>
            <h5>Private High School Classes (6 to 12)</h5>
            <p style={{textAlign: 'justify'}}>These are the courses recognized by Ministry of Education. We have courses from Grade (6) to Grade (12). The course structure is framed by combining two curricula; Government Curriculum and International Curriculum. For Government Curriculum, we teach them Myanmar, English, Maths, General Science, Geography and History as it is mentioned in Myanmar Official Text books. For international Curriculum, we encourage the students to use English in classroom and encourage them to study English, Maths and Science for certain extent. We train our students to be ready for Cambridge Assessment English such as KET, PET, FCE & CAE. Our main purpose here is to teach the high school students Government Curriculum thoroughly and International course in limited ways in order to enhance them to be well-trained students.</p>
            {/* <h5>Kindergarten Ages (4 to 5)</h5>

            <p style={{textAlign: 'justify'}}>This is specially aimed for kids who are about to attend primary courses in near
            future. We give them a very high exposure of learning Myanmar, English, Maths and
            General Knowledge in order to have the knowledge of Myanmar Government
            curriculum and International curriculum. For the purpose of giving them “fun and
            learn” attitude, we have special extra curriculum activities. We aim our students to list
            in both locally and internationally.</p> */}
            </div>
          <div className='col-lg-6 d-flex justify-content-center '>
            <img className='ms-lg-auto' src={cover} style={{width: '95%', objectFit: 'contain', borderRadius: '5px'}} alt="" />
          </div>
        </div>
        {/* <div className='container' style={{overflow: 'auto'}}>
          <h2 className='mb-4 mt-3 mt-lg-0'>Our Courses</h2>
        <table style={tableStyle}>
            <thead>
                <tr>
                    <th style={cellStyle} className='col-1'>စဉ်</th>
                    <th style={cellStyle} className='col-2'>ကျောင်း</th>
                    <th style={cellStyle} className='col-3'>အတန်း</th>
                    <th style={cellStyle} className='col-3'>သင်ရိုး</th>
                    <th style={cellStyle} className='col-2'>ဖြည့်စွက်သင်ရိုး</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={cellStyle} rowSpan="4">1</td>
                    <td style={cellStyle} rowSpan="4">အလယ်တန်း</td>
                    <td style={cellStyle}>ဆဌမတန်း (Grade 6)</td>
                    <td style={cellStyle}>
                    အခြေခံပညာသင်ရိုး  <br />
                    PET(Cambridge Exam Preparation) <br />
                    Maths,Science
                    </td>
                    <td style={cellStyle} rowSpan="6">
                    Art <br />
                    Music <br />
                    (Ukulele/Recorder) <br />
                    Dance <br />
                    Computer <br />
                    Sports / Yoga
                    </td>
                </tr>
                <tr>
                    <td style={cellStyle}>သတ္တမတန်း (Grade 7)</td>
                    <td style={cellStyle}>-</td>

                    
                </tr>
                <tr>
                    <td style={cellStyle}>အဌမတန်း(သင်ရိုးသစ်) (Grade-8)</td>
                    <td style={cellStyle}>
                    အခြေခံပညာသင်ရိုး  <br />
                    Pre-FCE
                    </td>
                </tr>
                <tr>
                    <td style={cellStyle}>နဝမတန်း (သင်ရိုးသစ်) (Grade-9)</td>
                    <td style={cellStyle}>
                    အခြေခံပညာသင်ရိုး  <br />
                    FCE <br />
                    (Cambridge Exam Preparation)
                    </td>
                </tr>
                <tr>
                    <td style={cellStyle} rowSpan="3">2</td>
                    <td style={cellStyle} rowSpan="3">အထက်တန်း</td>
                    <td style={cellStyle}>ဒသမတန်း (Grade-10)</td>
                    <td style={cellStyle}>-</td>
                </tr>
                <tr>
                    <td style={cellStyle}>ဧကဒသမတန်း (Grade-11)</td>
                    <td style={cellStyle}>
                    အခြေခံပညာသင်ရိုး  <br />
                    CAE <br />
                    (Cambridge Exam Preparation)
                    </td>
                </tr>
                <tr>
                    <td style={cellStyle}>ဒွာဒသမတန်း (Grade-12)</td>
                    <td style={cellStyle}>-</td>
                    <td style={cellStyle}>-</td>
                </tr>
            </tbody>
        </table>
        </div> */}
        <div className='container mt-4 mt-lg-0 col-12' style={{overflow: 'auto'}}>
        <img className='timetableone' style={{objectFit: 'contain', overflow: 'hidden'}} src={timetable1} alt="" />
        </div>
        <div className='container mt-4 col-12 mt-3' style={{overflow: 'auto'}}>
        <img className='timetableone' style={{objectFit: 'contain', overflow: 'hidden'}} src={timetable2} alt="" />
        </div>
        <div className="container py-lg-5 pt-5 px-0">
          <h2 className='mb-4 ps-3'>Our Class Photo</h2>
          <div className="d-lg-flex">
            <div className='col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={eng} alt="" />
            </div>
            <div className='col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={math} alt="" />
            </div>
            <div className='col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={science} alt="" />
            </div>
            {/* <div className='col-3 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={PrivatePrimaryCoursesIMG} alt="" />
            </div> */}

            
          </div>
        </div>
    </div>
  )
}

export default PrivateHighSchoolClasses