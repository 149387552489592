import React from 'react'
import cover from './weekendPhotos/cover.jpg'
import timetableone from './weekendPhotos/timetableone.jpg'
import one from './weekendPhotos/1.jpg'
import two from './weekendPhotos/2.jpg'
import three from './weekendPhotos/3.jpg'
import four from './weekendPhotos/4.jpg'
import six from './weekendPhotos/6.jpg'



const WeekendClasses = () => {
  return (
    <div style={{minHeight: '50vh'}}>
        <div className="d-lg-flex container boarder align-items-center" style={{minHeight: '500px'}}>
          <div className='col-lg-8'>
            <h2 className='mb-3'>Weekend Classes</h2>
            {/* <h5>Private High School Classes (Ages 5 to 14)</h5> */}
            <p style={{textAlign: 'justify'}}>These are the courses to prepare students for Cambridge Assessment English exams and to build their foundation in English. The students must be ages between 5 and 15. The course duration is 9 months which is from June to February. We are Cambridge recognized exam preparation Centre and we have been holding many Cambridge exams for about 10 years and above.</p>
            {/* <h5>Kindergarten Ages (4 to 5)</h5>

            <p style={{textAlign: 'justify'}}>This is specially aimed for kids who are about to attend primary courses in near
            future. We give them a very high exposure of learning Myanmar, English, Maths and
            General Knowledge in order to have the knowledge of Myanmar Government
            curriculum and International curriculum. For the purpose of giving them “fun and
            learn” attitude, we have special extra curriculum activities. We aim our students to list
            in both locally and internationally.</p> */}
            </div>
          <div className='col-lg-4 d-flex justify-content-center '>
            <img className='ms-lg-auto' src={cover} style={{width: '95%',height: '350px', objectFit: 'cover', borderRadius: '5px'}} alt="" />
          </div>
        </div>

        <div className='container mt-4 mt-lg-0 col-12' style={{overflow: 'auto'}}>
        <img className='timetableone' style={{objectFit: 'contain', overflow: 'hidden'}} src={timetableone} alt="" />
        </div>
        <div className="container pt-5 pb-lg-5 px-0">
          <h2 className='mb-4 ps-3'>Our Class Photo</h2>
          <div className="d-lg-flex flex-wrap justify-content-center">
            <div className='col-lg-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px', objectFit: 'cover'}} src={four} alt="" />
            </div>
            <div className='col-lg-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px', objectFit: 'cover'}} src={six} alt="" />
            </div>
            <div className='col-lg-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px', objectFit: 'cover'}} src={two} alt="" />
            </div>
            <div className='col-lg-4 mb-4 mb-lg-0 mt-lg-5 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px', objectFit: 'cover'}} src={one} alt="" />
            </div>
            <div className='col-lg-4 mb-4 mb-lg-0 mt-lg-5 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px', objectFit: 'cover'}} src={three} alt="" />
            </div>
            {/* <div className='col-3 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={NurseryPreKindergartenClassesIMG} alt="" />
            </div> */}

            
          </div>
        </div>
    </div>
  )
}

export default WeekendClasses