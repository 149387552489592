// import React from 'react'
import React, { Component, useEffect, useRef, useState } from 'react';
import { FacebookProvider, CustomChat } from 'react-facebook';

import logo from './logo.jpeg'
import Art2 from './Art-2.jpg'
import Art4 from './Art-4.jpg'
import homeone from './photos/homeone.jpg'
import hometwo from './photos/hometwo.jpg'
import homethree from './photos/homethree.jpg'
import ShinSawPuCover from './Classes/ShinSawPuPhotos/cover.jpeg'
import ParamiCover from './Classes/PrimarySchoolPhotos/cover.jpeg'
import YadanarCover from './Classes/PrivateHighSchoolPhotos/cover.jpg'

import Slider from "./Slider/Slider";
import { sliderOne } from './Slider/Images'
import { sliderTwo } from './Slider/Images'
import { sliderThree } from './Slider/Images'

import class1 from './Classes/ShinSawPuPhotos/cover.jpeg'
import class2 from './Classes/PrimarySchoolPhotos/cover.jpeg'
import class3 from './Classes/PrivateHighSchoolPhotos/cover.jpg'
import class4 from './Classes/weekendPhotos/cover.jpg'

import MessengerChat from './MessengerChat'



const Home = () => {
    const targetSectionRef = useRef(null);
    const ourClassesRef = useRef(null);

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    console.log(name, email, phone, message)

    const apiUrl = 'https://emailapi.ethical-digit.com/api/send-email';

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ name, message, email, phone, subject: 'Innovative Learning Insights: Streamline School Edition', company_email: 'infostreamlinetlc@gmail.com', company_name: 'Streamline Training-Learning Centre', company_address: '', company_logo: 'https://streamline.ethical-digit.com/emailLogo.png', company_phone: '01-657694, 01-662304' }),
            body: JSON.stringify({ name, message, email, phone, subject: 'Innovative Learning Insights: Streamline School Edition', company_email: 'infostreamlinetlc@gmail.com', company_name: 'Streamline Training-Learning Centre', company_address: '', company_logo: 'https://streamline.ethical-digit.com/emailLogo.png', company_phone: '01-657694, 01-662304', domain: 'streamline.ethical-digit.com' }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setEmail('')
                setMessage('')
                setPhone('')
                setName('')
                // Handle the response data as needed
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle errors
            });
    }

    const scrollToOurClasses = () => {
        if (ourClassesRef.current) {
            ourClassesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    function scrollIfHashIsPresent() {
        if (window.location.href.includes('#ourclasses')) {
            scrollToOurClasses();
        }
    }

    // Call the function initially
    scrollIfHashIsPresent();

    // Set up a periodic check using setInterval
    setInterval(scrollIfHashIsPresent, 1000);

    const scrollToSection = () => {
        targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <div className='d-lg-flex justify-content-center align-items-center container py-5' >
                <div className='fade-in-fwd col-lg-6 mb-5 mb-lg-0 col-12 d-flex flex-column justify-content-center align-items-center welcomediv'>
                    <h1 className='welcomeText w-100' style={{ color: '#7F367B', fontWeight: '600', letterSpacing: '.3rem' }}>STREAMLINE <br /> Training-Learning  <br />
                        <span style={{ color: '#7F367B', cursor: 'pointer' }}>Centre</span>
                        {/* <div class="containers">
                <svg width="300" height="100" viewBox="0 0 960 300">
                    <symbol id="s-text">
                    <text text-anchor="start" x="0%" y="70%">Center</text>
                    </symbol>

                    <g className="g-ants">
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>
                    <use xlinkHref="#s-text" className="text-copy"></use>

                    </g>
                </svg>
                </div> */}
                    </h1>
                    <p className='pt-4'>STLC is a dynamic learning place for Preschoolers, Primary students, Middle Students , High Students
                        and Cambridge exams learners. It is a centre where the medium of instruction is English.</p>
                    <div className='w-100 d-flex'>
                        {/* <button  className="button-89 me-4" role="button">
                    Learn More
                    </button>  */}
                        <button className="button-87" role="button"><a className='text-decoration-none text-white' onClick={scrollToSection}>Learn More</a>

                        </button>
                        <button className="button-87" role="button"><a className='text-decoration-none text-white' href="/contact">
                            Contact
                        </a>

                        </button>
                    </div>

                </div>

                <div style={{ zIndex: '1', backgroundImage: "url('https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/elements.png.webp')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className='flip-in-hor-top d-flex col-lg-6 col-12'>
                    <div className='col-lg-6 col-8 d-flex flex-column justify-content-center align-items-center' style={{ height: 'calc(90vh - 20px)' }}>
                        <div style={{ borderRadius: '50%', border: '3px solid #7F367B', objectFit: 'cover', overflow: 'hidden' }}><img className='welcomeImageHover' style={{ maxWidth: '200px', height: '220px' }} src={homethree} alt="" /></div>
                        <div style={{ borderRadius: '50%', border: '3px solid #7F367B', overflow: 'hidden', objectFit: 'cover', margin: '80px 0px 0px 40px' }}><img className='welcomeImageHover' style={{ width: '220px', height: '220px' }} src={homeone} alt="" /></div>

                    </div>
                    <div className='col-4 col-lg-6 d-flex justify-content-center align-items-center'>
                        <div style={{ borderRadius: '50%', border: '3px solid #7F367B', overflow: 'hidden', objectFit: 'cover' }}><img className='welcomeImageHover homewelcomethirdimg' src={hometwo} alt=""
                        /></div>
                    </div>
                </div>

            </div>
            <section ref={targetSectionRef} id="welcomeTostreamline">
                <div className='container text-center py-lg-5 mt-4 mt-lg-0 py-3' style={{ background: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', borderRadius: '20px' }}>
                    <h2 className='title1'>Welcome to Streamline</h2>
                    <p className='col-lg-6 m-auto col-12 pt-lg-3' style={{ color: 'white' }}>STLC is a dynamic learning place for Preschoolers, Primary students, Middle Students , High Students
                        and Cambridge exams learners. It is a centre where the medium of instruction is English.</p>
                    <div className="pt-5 d-lg-flex justify-content-around align-items-center" style={{ flexWrap: 'wrap' }}>
                        <div className='border text-start p-3 secondHomeHover gridone mb-2 mb-lg-0' style={{ boxShadow: '0px 0px 10px 0px rgba(21, 87, 153, 0.1)', borderRadius: '20px', background: 'white' }}>
                            <a className='text-decoration-none' style={{ color: 'black' }} href="/classes/NurseryPreKindergartenClasses">
                                <img className='mb-3' src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/mortarboard.png.webp" alt="" />
                                <h5 className='mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>International Preschool <br />(Shin Saw Pu Campus)</h5>
                                <p className='pt-2 pb-3 mb-0' style={{ fontSize: '.9rem', color: 'black' }}>No.11B,Shin Saw Pu Street, <br />Sanchaung Township, Yangon.
                                    <br />Phone: 09448022997 , 09943527632</p>
                                <img style={{ width: '100%', maxHeight: '236px', objectFit: 'cover' }} src={ShinSawPuCover} alt="" />

                            </a>
                        </div>
                        <div className='border text-start p-3 secondHomeHover gridone mb-2 mb-lg-0' style={{ boxShadow: '0px 0px 10px 0px rgba(21, 87, 153, 0.1)', borderRadius: '20px', background: 'white' }}>
                            <a className='text-decoration-none' style={{ color: 'black' }} href="classes/PrivatePrimaryCourses">
                                <img className='mb-3' src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/education.png.webp" alt="" />
                                <h5 className='mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Private Primary School <br />(Parami Campus)</h5>
                                <p className='pt-2 pb-3 mb-0' style={{ fontSize: '.9rem', color: 'black' }}>No. 24 Myasabai street, Parami, <br />Mayangone Township, Yangon.
                                    <br />Phone : 01-657694/018662425, 0976756642</p>
                                <img style={{ width: '100%', maxHeight: '236px', objectFit: 'cover' }} src={ParamiCover} alt="" />
                            </a>
                        </div>
                        <div className='border text-start p-3 secondHomeHover gridone mb-2 mb-lg-0' style={{ boxShadow: '0px 0px 10px 0px rgba(21, 87, 153, 0.1)', borderRadius: '20px', background: 'white' }}>
                            <a className='text-decoration-none' style={{ color: 'black' }} href="classes/PrivateHighSchoolClasses">
                                <img className='mb-3' src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/atom.png.webp" alt="" />
                                <h5 className='mb-0' style={{ fontWeight: '600', fontSize: '18px' }}>Private High School <br />(Yadanar Campus)</h5>
                                <p className='pt-2 pb-3 mb-0' style={{ fontSize: '.9rem', color: 'black' }}>No.767 Yadanar street , <br />Thingyankyun Township,Yangon.
                                    <br /> Phone: 09454257580,09459392264 </p>
                                <img style={{ width: '100%', maxHeight: '236px', objectFit: 'cover' }} src={YadanarCover} alt="" />
                            </a>
                        </div>
                        {/* <div className='border text-start p-3 secondHomeHover gridone mb-2 mb-lg-0' style={{ boxShadow: '0px 0px 10px 0px rgba(21, 87, 153, 0.1)', borderRadius: '20px'}}>
                    <img className='mb-3' src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/school-bag.png.webp" alt="" />
                    <h5 style={{fontWeight: '600', fontSize: '18px'}}>Awesome Teacher</h5>
                    <p className='pt-2 mb-0' style={{fontSize: '.9rem', color: '#575757'}}>Mauris nec mi feugiafr ingillat, cursus fringilla nec</p>
                </div> */}
                        {/* <div className='border text-start p-3 secondHomeHover gridone mb-2 mb-lg-0 mt-4' style={{ boxShadow: '0px 0px 10px 0px rgba(21, 87, 153, 0.1)', borderRadius: '20px'}}>
                    <img className='mb-3' src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/school-bag.png.webp" alt="" />
                    <h5 style={{fontWeight: '600', fontSize: '18px'}}>Awesome Teacher</h5>
                    <p className='pt-2 mb-0' style={{fontSize: '.9rem', color: '#575757'}}>Mauris nec mi feugiafr ingillat, cursus fringilla nec</p>
                </div> */}
                    </div>
                </div></section>
            <div className="gallary d-lg-flex d-sm-none container justify-content-around align-items-center py-5">
                <div style={{ width: 'auto', padding: '.65rem' }}>
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', width: '178.5px', height: '211px', objectFit: 'cover' }} src='./gallery/1.jpg' alt="" />
                </div>
                <div style={{ width: 'auto', padding: '.65rem' }}>
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', width: '275px', height: '355px', objectFit: "cover", marginBottom: '2.5rem' }} src='./gallery/2.jpg' alt="" />
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', width: '178px', height: '188.5px', objectFit: 'cover' }} src="./gallery/3.jpg" alt="" />
                </div>
                <div style={{ width: 'auto', padding: '.65rem' }}>
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', marginBottom: '2.5rem', width: '178.5px', height: '287.5px', objectFit: 'cover' }} src="./gallery/4.jpg" alt="" />
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', width: '178px', height: '188.5px', objectFit: 'cover' }} src='./gallery/5.jpg' alt="" />
                </div>
                <div style={{ width: 'auto', padding: '.65rem' }}>
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', marginBottom: '2.5rem', width: '178px', height: '188.5px', objectFit: 'cover' }} src="/gallery/6.jpg" alt="" />
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', width: '178.5px', height: '237.5px', objectFit: 'cover' }} src="/gallery/7.jpg" alt="" />
                </div>
                <div style={{ width: 'auto', padding: '.65rem' }}>
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', marginBottom: '2.5rem', width: '252.5px', height: '190.5px', objectFit: 'cover' }} src="./gallery/8.jpg" alt="" />
                    <img className='welcomeImageHover' style={{ borderRadius: '20px', border: '2px solid #7F367B', width: '178.5px', height: '237.5px', objectFit: 'cover' }} src="/gallery/9.jpg" alt="" />
                </div>


            </div>
            <section ref={ourClassesRef} id='ourclasses'>
                <div className='mx-lg-5 mx-2 px-2 py-lg-3 mt-5 mt-lg-0 py-2 text-center' style={{ background: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', borderRadius: '10px', color: 'white !important' }}>
                    <h2 className='title1'>Our Classes</h2>
                    <div className='d-lg-flex justify-content-center align-items-center'>
                        {/* <p style={{color: 'white'}} className='col-lg-8 text-center'>Nam mattis felis id sodales rutrum. Nulla ornare tristique mauris, a laoreet erat ornare sit amet. Nulla sagittis faucibus lacus</p> */}

                        {/* <button  className="button-87" role="button">
                   <span className='pe-2'>All Classes</span>  <i class="fa-solid fa-arrow-right"></i>
                </button> */}
                    </div>
                    <div className="d-lg-flex flex-wrap justify-content-around text-start align-items-top pt-lg-3 pt-4">
                        <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/NurseryPreKindergartenClasses">
                            <img style={{ width: '100%', height: '250px' }} src={class1} alt="" />
                            <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                                {/* <a className='text-decoration-none' href="/classes/NurseryClass"> */}
                                <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Nursery & Pre-Kindergarten Classes</h4>
                                {/* </a> */}

                            </div></a>
                        </div>
                        <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/PrivatePrimaryCourses">
                            <img style={{ width: '100%', height: '250px' }} src={class2} alt="" />
                            <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                                {/* <a className='text-decoration-none' href="/classes/KindergartenClass"> */}
                                <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Private Primary Classes <br /> (KG to GRADE - 4)</h4>
                                {/* </a> */}

                            </div></a>
                        </div>
                        <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/PrivateHighSchoolClasses">
                            <img style={{ width: '100%', height: '250px' }} src={class3} alt="" />
                            <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                                {/* <a className='text-decoration-none' href="/classes/PrivatePrimaryCourses"> */}
                                <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Private High School Classes <br /> (GRADE - 6 to GRADE - 12)</h4>
                                {/* </a> */}

                            </div></a>
                        </div>
                        <div className='secondHomeHover gridfour mb-5' style={{ borderTopLeftRadius: '10px', border: '1px solid white', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px', color: 'white' }}> <a className='text-white text-decoration-none' href="/classes/WeekendClasses">
                            <img style={{ width: '100%', height: '250px' }} src={class4} alt="" />
                            <div className='p-3' style={{ borderTop: '2px solid #575757' }}>

                                {/* <a className='text-decoration-none' href="/classes/PrivatePrimaryCourses"> */}
                                <h4 style={{ fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>Weekend Classes</h4>
                                {/* </a> */}

                            </div></a>
                        </div>
                        {/* <div className='secondHomeHover gridtwo me-4 mb-5' style={{boxShadow: "0px 0px 10px 0px rgba(21, 87, 153, 0.1)", borderTopLeftRadius: '10px', overflow: 'hidden', objectFit: 'contain', borderTopRightRadius: '10px'}}>
                    <img style={{width: '100%', }} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/school-counselor-and-pupil-768x512.jpg.webp" alt="" />
                    <div className='p-3' style={{borderBottom: '2px solid #575757', borderTop: '2px solid #575757'}}>
                        <p><span className='pe-2' style={{fontStyle: 'italic'}}>Mon-Fri</span> <span style={{fontStyle: 'italic'}}>10 AM - 12 AM</span></p>
                        <a className='text-decoration-none' href="EnvironmentalandStudiesEarthSciences"><h4 style={{fontSize: '20px', fontWeight: '500', lineHeight: '28px'}}>Environmental Studies & Earth Sciences</h4></a>
                        <div className='d-flex align-items-center pt-1'>
                            <img style={{width: '35px', borderRadius: '50%'}} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/elementor/thumbs/contemporary-businessman-pfuoi73guodv2uabxu7r47ifq5vfufxpjginqof5fe.jpg.webp" alt="" />
                           
                        </div>
                    </div>
                </div> */}
                    </div>
                </div>
            </section>
            <div className='container text-start py-lg-5'>
                <h2 className='text-center title1' style={{ color: 'black' }}>Announcement and Activities</h2>
                {/* <p className='col-lg-6 m-auto col-12 pt-lg-3 text-center'>ullam fringilla ipsum sed enim scelerisque, ac porttitor libero egestas. Donec iaculis nisi eget bibendum efficitur. Lorem ipsum dolor sit</p> */}
                <div className="d-flex flex-wrap justify-content-between align-items-center pt-5">
                    <div className='gridtwo mb-4 mb-lg-0' style={{ overflow: 'hidden', objectFit: 'contain' }}>
                        {/* <img className='welcomeImageHover' style={{width: '100%', borderRadius: '10px'}} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/painting-school-768x512.jpg.webp" alt="" /> */}
                        <Slider className="col-12" style={{ height: '500px' }} photos={sliderOne} />
                        <div className='py-3'>
                            {/* <p><span className='pe-2' style={{fontStyle: 'italic'}}>Mon-Fri</span> <span style={{fontStyle: 'italic'}}>10 AM - 12 AM</span></p> */}
                            <h4 style={{ fontSize: '20px', fontWeight: '500', lineHeight: '28px' }}>Shin Saw Pu Campus Activities</h4>
                            {/* <p style={{fontSize: '.9rem', color: '#575757'}}>July 9, 2020  No Comments</p> */}
                            <p style={{ color: '#575757' }}>STREAMLINE (Shin Saw Pu Campus) ၏ Nursery & Pre KG သားသား/မီးမီးလေးများရဲ့ တစ်နေ့တာ စာလေ့လာသင်ကြားမှု အတန်းတွင်းလုပ်ဆောင်မှုများကို မျှဝေပေးချင်ပါသည်။ နှစ်စဉ် သားသား/မီးမီးလေးများကို သင်ကြားပေးနေသော Myanmar, English, Maths , Science, General Knowledge & Chinese ဘာသာရပ်များ ကို တက်ကြွစွာလေ့လာသင်ကြားနေမှု ပုံရိပ်များ နှင့် ဘက်စုံဖွံ့ဖြိုးတိုးတက်စေရန် Holistic Educational Activities များလုပ်ဆောင်နေမှု မှတ်တမ်းများဖြစ်ပါသည်။</p>
                            <a className='text-white text-decoration-none' href="/contact"><button className="button-87" role="button">
                                Read More
                            </button></a>
                        </div>
                    </div>
                    <div className='gridtwo mb-4 mb-lg-0' style={{ overflow: 'hidden', objectFit: 'contain' }}>
                        {/* <img className='welcomeImageHover' style={{width: '100%', borderRadius: '10px'}} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/student-girl-with-easel-painting-at-art-school-768x512.jpg.webp" alt="" /> */}
                        <Slider className="col-12" style={{ height: '500px' }} photos={sliderTwo} />
                        <div className='py-3'>
                            {/* <p><span className='pe-2' style={{fontStyle: 'italic'}}>Mon-Fri</span> <span style={{fontStyle: 'italic'}}>10 AM - 12 AM</span></p> */}
                            <h4 style={{ fontSize: '20px', fontWeight: '500', lineHeight: '28px' }}>Parami Campus Activities</h4>
                            {/* <p style={{fontSize: '.9rem', color: '#575757'}}>July 9, 2020  No Comments</p> */}
                            <p style={{ color: '#575757' }}>STREAMLINE (Parami Campus) ၏ KG မှ Grade-4 ကျောင်းသား၊ကျောင်းသူလေးများရဲ့ တစ်နေ့တာ စာလေ့လာသင်ကြားမှု အတန်းတွင်းလုပ်ဆောင်မှုများကို မျှဝေပေးချင်ပါသည်။ နှစ်စဉ်ကျောင်းသား ကျောင်းသူလေးများကို သင်ကြားပေးနေသော အခြေခံသင်ရိုးများနှင့် နိုင်ငံတကာ ထပ်ဆောင်းသင်ရိုး (English, Maths & Science) ဘာသာရပ်များ ကို တက်ကြွစွာလေ့လာသင်ကြားနေမှု ပုံရိပ်များ နှင့် ဘက်စုံဖွံ့ဖြိုးတိုးတက်စေရန် Holistic Educational Activities များလုပ်ဆောင်နေမှု မှတ်တမ်းများဖြစ်ပါသည်။ </p>
                            <a className='text-white text-decoration-none' href="/contact"><button className="button-87" role="button">
                                Read More
                            </button></a>
                        </div>
                    </div>
                    <div className='gridtwo mb-4 mb-lg-0' style={{ overflow: 'hidden', objectFit: 'contain' }}>
                        {/* <img className='welcomeImageHover' style={{width: '100%', borderRadius: '10px'}} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/school-psychologist-and-boy-768x512.jpg.webp" alt="" /> */}
                        <Slider className="col-12" style={{ height: '500px' }} photos={sliderThree} />
                        <div className='py-3'>
                            {/* <p><span className='pe-2' style={{fontStyle: 'italic'}}>Mon-Fri</span> <span style={{fontStyle: 'italic'}}>10 AM - 12 AM</span></p> */}
                            <h4 style={{ fontSize: '20px', fontWeight: '500', lineHeight: '28px' }}>Yadanar Campus Activities</h4>
                            {/* <p style={{fontSize: '.9rem', color: '#575757'}}>July 9, 2020  No Comments</p> */}
                            <p style={{ color: '#575757' }}>STREAMLINE (Yadanar Campus) ၏ Grade-5 မှ Grade-11 ကျောင်းသား၊ကျောင်းသူလေးများရဲ့ တစ်နေ့တာ စာလေ့လာသင်ကြားမှု အတန်းတွင်းလုပ်ဆောင်မှုများကို မျှဝေပေးချင်ပါသည်။ နှစ်စဉ်ကျောင်းသား ကျောင်းသူလေးများကို သင်ကြားပေးနေသော အခြေခံသင်ရိုးများနှင့် နိုင်ငံတကာ ထပ်ဆောင်းသင်ရိုး (English, Maths & Science) ဘာသာရပ်များ ကို တက်ကြွစွာလေ့လာသင်ကြားနေမှု ပုံရိပ်များ နှင့် ဘက်စုံဖွံ့ဖြိုးတိုးတက်စေရန် Holistic Educational Activities များလုပ်ဆောင်နေမှု မှတ်တမ်းများဖြစ်ပါသည်။ </p>
                            <a className='text-white text-decoration-none' href="/contact"><button className="button-87" role="button">
                                Read More
                            </button></a>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundImage: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', borderRadius: '25px' }} className='container border d-flex flex-wrap justify-content-center align-items-center p-lg-5 p-4'>
                <div className='col-lg-6 col-12'>
                    <h3 style={{ fontSize: '36px', fontWeight: '700', lineHeight: '1', color: 'white' }}>Join us <br />and stay tuned!</h3>
                    <p style={{ color: 'white' }}>For more and detail information, you are mostly welcome to visit our school in
                        person.</p>
                </div>
                <div className='col-lg-6 col-12'>
                    <form onSubmit={handleSubmit}>
                        <div className='d-flex justify-content-between align-items-top'>
                            <div className='col-6 d-flex flex-column'>
                                <input onChange={(e) => setName(e.target.value)} value={name} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Name' type="text" name='Name' />
                                <input onChange={(e) => setEmail(e.target.value)} value={email} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Email' type="email" name='Email' />
                                <input onChange={(e) => setPhone(e.target.value)} value={phone} className='px-3 py-2' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Phone' name='Phone' />
                            </div>
                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} className='col-6 p-3' style={{ border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Message' name="Message" id="" cols="30" rows="3"></textarea>

                        </div>
                        <button type='submit' style={{ border: '2px solid #7F367B' }} className="button-87 ms-auto" role="button">
                            Send  <span className='ms-2'><i class="fa-solid fa-arrow-right"></i> </span>
                        </button>
                    </form>
                </div>
            </div>

            {/* <div style={{width: '50px', height: '50px', background: 'red'}}>
        <FacebookProvider appId="622764263383800" chatSupport>
            <CustomChat pageId="114713778285523" minimized={false}/>
        </FacebookProvider> 
        </div> */}
        </>
    )
}

export default Home