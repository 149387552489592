import React from 'react'
import NurseryPreKindergartenClassesIMG from './NurseryPreKindergartenClasses.jpeg'
import four from './ShinSawPuPhotos/four.jpeg'
import six from './ShinSawPuPhotos/six.jpeg'
import two from './ShinSawPuPhotos/two.jpeg'

import timetableone from './timetableone.jpg'

const NurseryPreKindergartenClasses = () => {
  const tableStyle = {
    // border: '1px solid black', // Set the border style here
    borderCollapse: 'collapse', // Optional: To collapse borders
    width: '100%', // Optional: Set the table width
};

const cellStyle = {
    border: '1px solid black', // Set the border style for cells
    // borderRadius: '9px',
    padding: '8px', // Optional: Add padding to cells
    // background: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)',
    // background: 'pink',
    // color: 'white',
};
  return (
    <div style={{minHeight: '100vh'}}>
        <div className="d-lg-flex container boarder align-items-center" style={{minHeight: '500px'}}>
          <div className='col-lg-6 '>
            <h2 className='mb-3'>Nursery & Pre-Kindergarten Classes</h2>
            <h5>Nursery (Ages 2 to 5)</h5>
            <p style={{textAlign: 'justify'}}>It is mainly conducted for the children of ages 2 to 5 years old. We give Nursery
            kids ages 2 to 4 a special caring and an effective English language speaking
            environment physical intellectual development through learning environment. They
            can enjoy Learning English, Maths, General Knowledge and other activities like music,
            dance, songs, rhymes, art, and stories in a pleasant conditional atmosphere.</p>
            <h5>Pre-Kindergarten (Ages 4 to 5)</h5>

            <p style={{textAlign: 'justify'}}>This is specially aimed for kids who are about to attend primary courses in near
            future. We give them a very high exposure of learning Myanmar, English, Maths and
            General Knowledge in order to have the knowledge of Myanmar Government
            curriculum and International curriculum. For the purpose of giving them “fun and
            learn” attitude, we have special extra curriculum activities. We aim our students to list
            in both locally and internationally.</p>
            </div>
          <div className='col-lg-6 col-12 d-flex justify-content-center '>
            <img className='ms-lg-auto' src={NurseryPreKindergartenClassesIMG} style={{width: '95%', objectFit: 'contain', borderRadius: '5px'}} alt="" />
          </div>
        </div>
        <div className='container mt-4 mt-lg-0 col-12' style={{overflow: 'auto'}}>
          {/* <h2 className='mb-4'>Our Courses</h2> */}
        {/* <table style={tableStyle}>
            <thead>
                <tr>
                    <th style={cellStyle} className='col-1'>No</th>
                    <th style={cellStyle} className='col-3'>Class</th>
                    <th style={cellStyle} className='col-3'>Day/Time</th>
                    <th style={cellStyle} className='col-3'>Subject</th>
                    <th style={cellStyle} className='col-2'>Extracurriculum</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={cellStyle}>1</td>
                    <td style={cellStyle}>Nursery (3 yrs to 4 yrs)</td>
                    <td style={cellStyle}>
                        Monday to Friday<br />
                        9:00 to 2:00
                    </td>
                    <td style={cellStyle}>
                        English, Maths, Science, G.K
                    </td>
                    <td style={cellStyle}>
                        Chinese<br />
                        Myanmar <br /> Other Activities
                    </td>
                </tr>
                <tr>
                    <td style={cellStyle}>2</td>
                    <td style={cellStyle}>Pre-KG</td>
                    <td style={cellStyle}>
                        Monday to Friday<br />
                        9:00 to 2:00
                    </td>
                    <td style={cellStyle}>
                        English, Maths, Science, G.K
                    </td>
                    <td style={cellStyle}>
                        Chinese<br />
                        Myanmar <br /> Other Activities
                    </td>
                </tr>
            </tbody>
        </table> */}
        <img className='timetableone' style={{objectFit: 'contain', overflow: 'hidden'}} src={timetableone} alt="" />
        </div>
        <div className="container pt-5 pb-lg-5 px-0">
          <h2 className='mb-4 ps-3'>Our Class Photo</h2>
          <div className="d-lg-flex">
            <div className='col-lg-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px'}} src={four} alt="" />
            </div>
            <div className='col-lg-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '291px', borderRadius: '5px'}} src={six} alt="" />
            </div>
            <div className='col-lg-4 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%',maxHeight: '251px', borderRadius: '5px', objectFit: 'cover'}} src={two} alt="" />
            </div>
            {/* <div className='col-3 d-flex justify-content-center align-items-center'>
            <img style={{width: '90%', borderRadius: '5px'}} src={NurseryPreKindergartenClassesIMG} alt="" />
            </div> */}

            
          </div>
        </div>
    </div>
  )
}

export default NurseryPreKindergartenClasses