import React, { useEffect, useState } from 'react'
import logo from './logo3.png'

const Footer = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentYear(new Date().getFullYear());
        }, 60000); // Update every minute
    
        return () => {
          clearInterval(intervalId);
        };
      }, []);

  return (
    <>
    <div className="container d-flex flex-wrap flex-wrap justify-content-around pt-5 pb-2">
        <div  className="gridfooter footerlogo mb-4 d-sm-none d-lg-block">
            <img style={{width: '70%'}} src={logo} alt="" />
            {/* <p style={{color: '#575757'}}>Vivamus porta efficitur nibh nec convallis. Vestibulum egestas eleifend justo. Ut tellus ips, accumsan</p> */}
        </div>
        <div className="gridfooter mb-4">
            <h5 className='mb-4' style={{fontSize: '18px', fontWeight: '500'}}>Our Contacts</h5>
            <div className='d-flex align-items-top mb-4'>
                <i style={{fontSize: '1.2rem',color: '#7F367B'}} class="fa-solid fa-phone me-2"></i>
                <div>
                    <p className='mb-0'>Call</p>
                    <p className='mb-0' style={{color: '#575757'}}>01-657694, 01-662304</p>
                </div>
            </div>
            <div className='d-flex align-items-top mb-4'>
                <i style={{fontSize: '1.2rem',color: '#7F367B'}} class="fa-solid fa-clock me-2"></i>
                <div>
                    <p className='mb-0'>Working Time</p>
                    <p className='mb-0' style={{color: '#575757'}}>Mon - Fri 9 AM - 4 PM</p>
                </div>
            </div>
            <div className='d-flex align-items-top '>
            <i style={{fontSize: '1.2rem',color: '#7F367B'}} class="fa-solid fa-location-dot me-2"></i>
                <div>
                    <p className='mb-0'>Address</p>
                    <p className='mb-0' style={{color: '#575757'}}><span style={{fontWeight: 'bold'}}>International Preschool</span>  <br />(Shin Saw Pu Campus)</p>
                    <p className='mb-0' style={{color: '#575757'}}><span style={{fontWeight: 'bold'}}>Private Primary School</span>  <br />(Parami Campus)</p>
                    <p className='mb-0' style={{color: '#575757'}}><span style={{fontWeight: 'bold'}}>Private High School</span> <br />(Yadanar Campus)</p>
                </div>
            </div>
        </div>
        <div className="gridfooter mb-4">
            <h5 className='mb-4' style={{fontSize: '18px', fontWeight: '500'}}>Quick Links</h5>
            <ul className='p-0'>
                <li className='text-decoration-none pb-3' style={{listStyle: 'none', color: '#575757'}}> <a className='text-decoration-none text-black' href="/about">About Us</a> </li>
                {/* <li className='text-decoration-none pb-3' style={{listStyle: 'none', color: '#575757'}}>Classes</li> */}
                <li className='text-decoration-none pb-3' style={{listStyle: 'none', color: '#575757'}}> <a className='text-decoration-none text-black' href="/contact">Contact</a></li>
            </ul>
        </div>
        <div className="gridfooter" style={{ height: 'auto !important'}}>
        <iframe style={{height: '100%'}} loading="lazy"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1909.2265653255095!2d96.16035513872798!3d16.853464676561476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1936519e8d96f%3A0xbdb3155ec8192a8f!2sStreamline%20Training%20-%20Learning%20Centre!5e0!3m2!1sen!2smm!4v1692765405185!5m2!1sen!2smm"
					title="London Eye, London, United Kingdom"
					aria-label="London Eye, London, United Kingdom"
			></iframe>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1909.2265653255095!2d96.16035513872798!3d16.853464676561476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1936519e8d96f%3A0xbdb3155ec8192a8f!2sStreamline%20Training%20-%20Learning%20Centre!5e0!3m2!1sen!2smm!4v1692765405185!5m2!1sen!2smm" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </div>
    </div>
    <hr />
    <div className='d-flex justify-content-between align-items-center container py-2'>
        <p>© Copyrights {currentYear} Powered by <a className='text-decoration-none' href="https://cbs.com.mm/">CBS</a></p>
        <div className='d-flex'>
        <a href='https://www.facebook.com/profile.php?id=100070428040332&mibextid=LQQJ4d' class="border-0 me-2 d-flex justify-content-center align-items-center text-decoration-none" style={{background: '#7F367B', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer'}}>

         <i class="fa-brands text-white fa-facebook-f border-0"></i>
        </a>
        {/* <a class="border-0 me-2 d-flex justify-content-center align-items-center text-decoration-none" style={{background: '#7F367B', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer'}}>
        <i class="fa-brands text-white fa-linkedin-in"></i>
        </a>
        <a class="border-0 me-2 d-flex justify-content-center align-items-center text-decoration-none" style={{background: '#7F367B', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer'}}>
        <i class="fa-brands text-white fa-instagram"></i>
        </a> */}
        </div>
    </div>
    </>
  )
}

export default Footer