import React from 'react'
import { useState } from 'react'

const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    console.log(name, email, phone, message)

    const apiUrl = 'https://emailapi.ethical-digit.com/api/send-email';

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ name, message, email, phone, subject: 'Innovative Learning Insights: Streamline School Edition', company_email: 'infostreamlinetlc@gmail.com', company_name: 'Streamline Training-Learning Centre', company_address: '', company_logo: 'https://streamline.ethical-digit.com/emailLogo.png', company_phone: '01-657694, 01-662304' }),
            body: JSON.stringify({ name, message, email, phone, subject: 'Innovative Learning Insights: Streamline School Edition', company_email: 'infostreamlinetlc@gmail.com', company_name: 'Streamline Training-Learning Centre', company_address: '', company_logo: 'https://streamline.ethical-digit.com/emailLogo.png', company_phone: '01-657694, 01-662304', domain: 'streamline.ethical-digit.com' }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setEmail('')
                setMessage('')
                setPhone('')
                setName('')
                // Handle the response data as needed
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle errors
            });
    }

    return (
        <>
            <div className='container py-5 d-flex flex-wrap'>
                <div className='col-lg-6 col-12 mb-4 mb-lg-0'>
                    <h3 className='mb-4' style={{ fontSize: '36px', fontWeight: '500' }}>Our Contacts</h3>
                    <div className='d-flex align-items-top mb-5'>
                        <img className='me-4' style={{ width: '50px', height: '50px' }} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/24-hours-1.png.webp" alt="" />
                        {/* <i style={{fontSize: '1.2rem',color: '#7F367B'}} class="fa-solid fa-phone me-2"></i> */}
                        <div>
                            <p className='mb-0'>Call</p>
                            <p className='mb-0' style={{ color: '#575757' }}>01-657694, 01-662304</p>
                            <p className='mb-0' style={{ color: '#575757' }}>(during office hours)</p>

                        </div>
                    </div>
                    <div className='d-flex align-items-top mb-5'>
                        <img className='me-4' style={{ width: '50px' }} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/clock-1.png.webp" alt="" />
                        <div>
                            <p className='mb-0'>Working Time</p>
                            <p className='mb-0' style={{ color: '#575757' }}>Mon - Fri 9 AM - 4 PM</p>
                        </div>
                    </div>
                    <div className='d-flex align-items-top '>
                        <img className='me-4' style={{ width: '50px', height: '50px' }} src="https://shelly.templatekit.co/wp-content/uploads/sites/35/2020/07/map.png.webp" alt="" />
                        <div>
                            <p className='mb-0'>Address</p>
                            {/* <p className='mb-0' style={{color: '#575757'}}>Franklin St, Greenpoint Ave</p> */}
                            <p className='mb-0' style={{ color: '#575757' }}><span style={{ fontWeight: 'bold' }}>International Preschool</span>  <br />(Shin Saw Pu Campus) <br /> (09448022997 , 09943527632)</p>
                            <p className='mb-0' style={{ color: '#575757' }}><span style={{ fontWeight: 'bold' }}>Private Primary School</span>  <br />(Parami Campus) <br />(01-657694/018662425, 0976756642)</p>
                            <p className='mb-0' style={{ color: '#575757' }}><span style={{ fontWeight: 'bold' }}>Private High School</span> <br />(Yadanar Campus) <br />(09454257580,09459392264)</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <h3 className='mb-4' style={{ fontSize: '36px', fontWeight: '500' }}>Send Us a Message</h3>
                    <form onSubmit={handleSubmit}>
                        <div className='d-flex justify-content-between align-items-top'>
                            <div className='col-6 d-flex flex-column'>
                                <input onChange={(e) => setName(e.target.value)} value={name} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Name' type="text" name='Name' />
                                <input onChange={(e) => setEmail(e.target.value)} value={email} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Email' type="email" name='Email' />
                                <input onChange={(e) => setPhone(e.target.value)} value={phone} className='px-3 py-2' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Phone' name='Phone' />
                            </div>
                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} className='col-6 p-3' style={{ border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Message' name="Message" id="" cols="30" rows="3"></textarea>

                        </div>
                        <button type='submit' style={{ border: '2px solid #7F367B' }} className="button-87 ms-auto" role="button">
                            Send  <span className='ms-2'><i class="fa-solid fa-arrow-right"></i> </span>
                        </button>
                    </form>

                </div>
            </div>
            <div className='col-12 pb-5 container' style={{ height: 'auto !important' }}>
                <iframe style={{ width: '100%', height: '500px' }} loading="lazy"
                    // src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1909.2265653255095!2d96.16035513872798!3d16.853464676561476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1936519e8d96f%3A0xbdb3155ec8192a8f!2sStreamline%20Training%20-%20Learning%20Centre!5e0!3m2!1sen!2smm!4v1692765405185!5m2!1sen!2smm"
                    title="London Eye, London, United Kingdom"
                    aria-label="London Eye, London, United Kingdom"
                ></iframe>
            </div>

            <div style={{ backgroundImage: 'linear-gradient(90deg, rgba(182,0,141,1) 0%, rgba(121,5,101,1) 55%, rgba(192,0,255,1) 100%)', borderRadius: '25px' }} className='container border d-flex flex-wrap justify-content-center align-items-center p-lg-5 p-4'>
                <div className='col-lg-6 col-12 text-white'>
                    <h3 style={{ fontSize: '36px', fontWeight: '700', lineHeight: '1' }}>Join us <br />and stay tuned!</h3>
                    <p>For more and detail information, you are mostly welcome to visit our school in person </p>
                </div>
                <div className='col-lg-6 col-12'>
                    <form onSubmit={handleSubmit}>
                        <div className='d-flex justify-content-between align-items-top'>
                            <div className='col-6 d-flex flex-column'>
                                <input onChange={(e) => setName(e.target.value)} value={name} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Name' type="text" name='Name' />
                                <input onChange={(e) => setEmail(e.target.value)} value={email} className='px-3 py-2 mb-3' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Email' type="email" name='Email' />
                                <input onChange={(e) => setPhone(e.target.value)} value={phone} className='px-3 py-2' style={{ width: 'calc(100% - 10px)', border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Phone' name='Phone' />
                            </div>
                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} className='col-6 p-3' style={{ border: '2px solid #7F367B', borderRadius: '20px' }} placeholder='Message' name="Message" id="" cols="30" rows="3"></textarea>

                        </div>
                        <button type='submit' style={{ border: '2px solid #7F367B' }} className="button-87 ms-auto" role="button">
                            Send  <span className='ms-2'><i class="fa-solid fa-arrow-right"></i> </span>
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Contact